import React from 'react'

const Resources = () => {
  return (
    <div id='resources'>
        <div >
            <div className='row'>
                <div className='col l6 m6 s12'>
                    <div id='travel-info'>
                        <table id='travel-info-table'>
                            <thead>
                                <tr>
                                    <td className='center'>TRAVEL INFO</td>
                                </tr>
                            </thead>
                            <tr>
                                <td><a href="forms_and_documents/game_change_request_edp_and_lijsl.pdf" target="_blank">GAME CHANGE REQUEST</a></td>
                            </tr>
                            <tr>
                                <td><a href="forms_and_documents/club_pass_guest_players.pdf" target="_blank">CLUB PASS/GUEST PLAYERS</a></td>
                            </tr>
                            <tr>
                                <td><a href="forms_and_documents/field_requests.pdf" target="_blank">FIELD REQUESTS FOR GAMES</a></td>
                            </tr>
                            <tr>
                                <td><a href="forms_and_documents/field_permits.pdf" target="_blank">FIELD PERMITS</a></td>
                            </tr>
                            <tr>
                                <td><a href="forms_and_documents/registration_instructions.pdf" target="_blank">REGISTRATION INSTRUCTIONS</a></td>
                            </tr>
                            <tr>
                                <td><a href="forms_and_documents/aed_assurance_affirmation_and_action_forms.pdf" target="_blank">AED FORMS</a></td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className='col l6 m6 s12'>
                    <div id='forms-and-documents'>
                        <table id='forms-and-documents-table'>
                            <thead>
                                <tr>
                                    <td className='center'>FORMS AND DOCUMENTS</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a href='forms_and_documents/lgn_fall2024_schedule.pdf' target='_blank'>FALL 2024 INTRAMURAL SCHEDULE</a></td>
                                </tr>
                                <tr>
                                    <td><a href='forms_and_documents/usys-medical-release.pdf' target='_blank'>MEDICAL RELEASE FORM (USYS)</a></td>
                                </tr>
                                <tr>
                                    <td><a href="forms_and_documents/hardship-application.pdf" target="_blank">HARDSHIP APPLICATION</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Resources